import { useEffect, useState } from 'react';
import AssistantWindow from '../assistant-window/assistant-window';
import AssistantButton from '../assistant-button/assistant-button';
import { UserId } from '../../App';

export enum ActorsEnum {
    User = 0,
    System = 1,
    Assistant = 3
}

export interface IPreviousChat {
    id: string,
    name: string,
    createdAt: string,
    lastMessage: {
        id: string,
        actor: ActorsEnum,
        content: "string",
        createdAt: "2024-09-14T16:52:08.475Z"
    }
}
function WindowManager() {
    const [assistantWindowOpen, setAssistantWindowOpen] = useState(false);
    const [previousChats, setPreviousChats] = useState<IPreviousChat[]>([]);

    useEffect(() => {
        fetch(`https://invenassist.somee.com/api/${UserId}/chats`)
            .then(res => res.json())
            .then(res => setPreviousChats(res))
            .catch(err => console.error(err))
    }, [])

    return (
        assistantWindowOpen ?
            <AssistantWindow previousChats={previousChats} setAssistantWindowOpen={setAssistantWindowOpen}></AssistantWindow> :
            <AssistantButton setAssistantWindowOpen={setAssistantWindowOpen}></AssistantButton>
    );
}

export default WindowManager;
