import './message.css';
import { IMessage } from '../assistant-window';
import { ActorsEnum } from '../../window-manager/window-manager';
import { useEffect, useRef } from 'react';

interface MessageProps {
    message: IMessage,
}

const urlsRegex = /(?:(?:https?|ftp|file):\/\/|www\.|ftp\.)(?:\([-A-Z0-9+&@#\/%=~_|$?!:,.]*\)|[-A-Z0-9+&@#\/%=~_|$?!:,.])*(?:\([-A-Z0-9+&@#\/%=~_|$?!:,.]*\)|[A-Z0-9+&@#\/%=~_|$])/igm;

function Message(props: MessageProps) {
    const textContainerRef = useRef(null);
    useEffect(() => {
        setTextWIthUrls();
    }, [props.message])

    function setTextWIthUrls(): any {
        const urls = props.message.content.match(urlsRegex);
        let newContent = props.message.content;

        if (urls && urls.length) {
            urls.forEach((url) => {
                newContent = newContent.replace(url, `<a target="_blank" className="underline" href={url}>${url}</a>`)
            })
        }

        (textContainerRef.current as unknown as HTMLElement).innerHTML = newContent;
    }

    return (
        <>
            <span className={`text-sm text-gray-500 ${props.message.actor === ActorsEnum.User ? 'self-end' : 'self-start'}`}>{new Date(props.message.createdAt).toLocaleTimeString()}</span>
            <div ref={textContainerRef} className={`break-all flex-col text-white rounded flex text-wrap p-2 max-w-full ${props.message.actor === ActorsEnum.User ? "bg-smgBackgroundColor-300 self-end" : "bg-gray-400 self-start"}`}>
            </div>

        </>
    );
}

export default Message;
