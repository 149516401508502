import './assistant-window.css';
import { useEffect, useRef, useState } from "react";
import { smileUserOnline } from '../../assets/icons/smile';
import { sendMessageIconSize5, sendMessageIconSize6 } from '../../assets/icons/sendMessage';
import { chevronDown } from '../../assets/icons/chevronDown';
import { chevronLeft } from '../../assets/icons/chevronLeft';
import Message from './message/message';
import { ActorsEnum, IPreviousChat } from '../window-manager/window-manager';
import { UserId } from '../../App';
import { spinnerArrowsIcon } from '../../assets/icons/spinnerArrows';
import { MediaRecorder, register } from 'extendable-media-recorder';
import { connect } from 'extendable-media-recorder-wav-encoder';
import { Stream } from 'stream';
import { micro } from '../../assets/icons/micro';

enum WindowTypesEnum {
    Welcome = 0,
    Chat = 1
}

interface AssistantWindowProps {
    setAssistantWindowOpen: (arg0: boolean) => void,
    previousChats: IPreviousChat[]
}

export interface IMessage {
    actor: ActorsEnum
    content: string
    createdAt: string
    id: string
}


function AssistantWindow(props: AssistantWindowProps) {
    let addedEventListener = false;
    const inputRef = useRef(null);
    const [chatId, setChatId] = useState<string>('');
    const [displayedWindow, setDisplayedWindow] = useState(0);
    const [messages, setMessages] = useState<IMessage[]>([]);

    const [ isLoading, setIsLoading ] = useState<boolean>(false);
    const [ stream, setStream ] = useState<MediaStream | null>(null);
    const [ isS2tMode, setIsS2tMode ] = useState<boolean>(false);
    const [ isEncoderSet, setIsEncoderSet ] = useState<boolean>(false);

    useEffect(() => {
        if (addedEventListener) {
            (inputRef.current as unknown as HTMLElement)?.addEventListener("keypress", handleInputKeypress);
            addedEventListener = true;
        } else {
            (inputRef.current as unknown as HTMLElement)?.removeEventListener("keypress", handleInputKeypress);
            addedEventListener = false;
        }
    }, [])

    function handleInputKeypress(event: KeyboardEvent): boolean {
        return event.keyCode !== 13;
    }

    function handleCollapseClick(): void {
        props.setAssistantWindowOpen(false);
    }

    function handleWindowSwitch(window: WindowTypesEnum) {
        if (window === WindowTypesEnum.Chat) {
            createNewChat()
                .then(res => setChatId(res))
                .catch(err => console.error(err))
        }
        setDisplayedWindow(window);
    }

    async function createNewChat(): Promise<string> {
        const settings = {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ userId: UserId })
        }
        try {
            const chatIdRequest = await fetch(`https://invenassist.somee.com/api/chats`, settings);
            return await chatIdRequest.json()
        } catch (e: any) {
            return e
        }
    }

    function handleSendMessage(content: string) {
        setIsLoading(true)
        sendMessage(content)
            .then(res => {
                setIsLoading(false);
                const oldMessages = [...messages];
                res.forEach((msg: IMessage) => oldMessages.unshift(msg))
                setMessages(oldMessages);
            })
            .catch(err => {
                setIsLoading(false);
                console.error(err)
            });
        (inputRef.current as unknown as HTMLElement).innerHTML = '';
    }

    async function sendMessage(content: string) {
        const settings = {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ content })
        }
        try {
            const sendMessageRequest = await fetch(`https://invenassist.somee.com/api/chat/${chatId}/messages`, settings);
            return await sendMessageRequest.json()
        } catch (e: any) {
            return e
        }
    }

    async function sendTranscriptionRequest(content: ArrayBuffer, sampleRate: number) {
        const formData = new FormData();

        formData.append('wave', new Blob([content]))
        formData.append('sampleRate', sampleRate.toString());

        const settings = {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
            },
            body: formData
        }
        try {
            const sendMessageRequest = await fetch(`https://hackaton-inventioneers-s2t.somee.com/api/s2t`, settings);
            return await sendMessageRequest.json()
        } catch (e: any) {
            return e
        }
    }


    async function stopListen() {
        stream!.getTracks().forEach((track) => track.stop());

        setStream(null);
    }

    async function handleStartListen() {
        var SAMPLE_RATE = 48000;

        if (!isEncoderSet) {
            await register(await connect());
            setIsEncoderSet(true);
        }

        const audioStream = await navigator.mediaDevices.getUserMedia({ audio: { sampleRate: SAMPLE_RATE, channelCount: 1 } });

        setStream(audioStream);
        let recorder = new MediaRecorder(audioStream, { mimeType: 'audio/wav' });
        recorder.addEventListener("dataavailable", async (event) => {
          if (event.data.size > 0) {
            const buffer = await event.data.arrayBuffer();
            const modifiedBuffer = buffer.slice(44);

            if (!isS2tMode) {
                return;
            }

            setIsLoading(true);
            sendTranscriptionRequest(modifiedBuffer, SAMPLE_RATE)
                .then((text) => {
                    if (isS2tMode) {
                        (inputRef.current as unknown as HTMLElement).innerHTML = text;
                        setIsS2tMode(false);
                    }
                })
                .finally(() => setIsLoading(false));
          }
        });

        recorder.start();
    }

    function isButtonDisabled(): boolean {
        return !chatId || isLoading;
    }

    const welcomeWindow =
        <div className="min-h-96 w-[24rem] fixed bottom-24 right-5 flex flex-col
        bg-gradient-to-b from-smgBackgroundColor-100 from-10% to-white p-6 gap-4 select-none
        animate-slide-in-up">
            <span onClick={handleCollapseClick} className="self-end cursor-pointer">{chevronDown}</span>
            <h1 className="self-center text-white text-xl">Welcome to VentiMate</h1>
            <div className="rounded bg-white p-4 flex gap-2">
                <div className="self-center">{smileUserOnline}</div>
                <div className="flex flex-col self-center">
                    <span className="text-gray-500 text-sm">{`VentiMate Bot - ${new Date().toLocaleTimeString()}`}</span>
                    <span>Hello {String.fromCodePoint(Number('0x1f603'))}</span>
                </div>
            </div>
            <div
                className="rounded bg-black p-4 flex justify-center gap-2 cursor-pointer transition-transform hover:scale-105 active:scale-95"
                onClick={() => handleWindowSwitch(WindowTypesEnum.Chat)}>
                <span className="text-white">Send message</span>
                <span className="self-center">{sendMessageIconSize5}</span>
            </div>
        </div>;

    const buttons = !isS2tMode
        ?
            <>
                <button
                    className="bg-smgBackgroundColor-300 hover:bg-smgBackgroundColor-200 text-white font-bold py-2 px-2
                        rounded rounded-full focus:outline-none focus:shadow-outline min-w-max h-1/3 self-end active:scale-90 shrink"
                    type="button"
                    disabled={!chatId || isLoading}
                    onClick={() => handleSendMessage((inputRef.current as unknown as HTMLElement).innerHTML)}>
                    { isLoading ? spinnerArrowsIcon : sendMessageIconSize5 }
                </button>
                <button
                    className="bg-smgBackgroundColor-300 hover:bg-smgBackgroundColor-200 text-white font-bold py-2 px-2
                        rounded rounded-full focus:outline-none focus:shadow-outline min-w-max h-1/3 self-end active:scale-90 shrink"
                    type="button"
                    disabled={!chatId || isLoading}
                    onClick={() => setIsS2tMode(true)}>
                    {micro}
                </button>
            </>
        :
            <>
                {
                    !stream
                        ?
                        <button
                            className="bg-smgBackgroundColor-300 hover:bg-smgBackgroundColor-200 text-white font-bold py-2 px-2
                                   rounded rounded-full focus:outline-none focus:shadow-outline min-w-max h-1/3 self-end active:scale-90 shrink"
                            type="button"
                            disabled={!chatId || isLoading}
                            onClick={() => handleStartListen()}>
                            { !isLoading ? micro : spinnerArrowsIcon }
                        </button>
                        :
                        <button
                            className="bg-smgBackgroundColor-100 hover:bg-smgBackgroundColor-200 text-white font-bold py-2 px-2
                                   rounded rounded-full focus:outline-none focus:shadow-outline min-w-max h-1/3 self-end active:scale-90 shrink"
                            type="button"
                            disabled={!chatId || isLoading}
                            onClick={() => stopListen()}>
                            { !isLoading ? micro : spinnerArrowsIcon }
                        </button>
                }
                <button
                    className="leading-none text-xl bg-smgBackgroundColor-300 hover:bg-smgBackgroundColor-200 text-white font-bold py-2 px-3
                           rounded rounded-full focus:outline-none focus:shadow-outline min-w-max h-1/3 self-end active:scale-90 shrink align-middle"
                    type="button"
                    disabled={!chatId }
                    onClick={() => { setIsS2tMode(false); stopListen(); } }>
                    x
                </button>
            </>;

    const chatWindow =
        <div className="min-h-96 w-[24rem] fixed bottom-24 right-5 bg-white flex flex-col animate-slide-out-right">
            <div className="w-full basis-1/6 bg-smgBackgroundColor-100 flex content-center p-4">
                <div onClick={() => handleWindowSwitch(WindowTypesEnum.Welcome)} className="self-center cursor-pointer h-8 w-8 flex">
                    {chevronLeft}
                </div>

                <p className="text-white text-lg self-center select-none">Ask me anything</p>
            </div>

            <div className="min-h-[24rem] max-h-64 w-full basis-4/6 border-x border-b flex flex-col-reverse p-4 gap-2 overflow-y-scroll">
                {
                    messages.map((message) => {
                        return <Message message={message}></Message>;
                    })
                }
            </div>

            <div className="w-full basis-1/6 border-x border-b p-4 flex content justify-center content-center gap-4">
                <div ref={inputRef}
                     className="shadow appearance-none border rounded width-200px py-2 px-3 basis-3/4
                     leading-tight focus:outline-none focus:shadow-outline"
                     contentEditable={true}>
                </div>
                { buttons }
            </div>
        </div>;

    function renderWindow(): any {
        switch (displayedWindow) {
            case 0:
                return welcomeWindow
                break;
            case 1:
                return chatWindow
        }
    }

    return renderWindow();
}

export default AssistantWindow;
