import './App.css';
import { useEffect, useState } from 'react';
import WindowManager from './components/window-manager/window-manager';

export const UserId = '4b9e2c00-5d75-40f5-93bd-e7ae03e898aa'
export const UserName = 'Stas';

function App() {
    return (
        <div className="background-image">
            <WindowManager></WindowManager>
        </div>
    );
}

export default App;
