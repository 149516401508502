import './assistant-button.css';
import ventionLogo from '../../assets/images/vention-logo.jpeg'

interface AssistantButtonProps {
    setAssistantWindowOpen: (arg0: boolean) => void,
}

function AssistantButton(props: AssistantButtonProps) {
    function handleClick() {
        props.setAssistantWindowOpen(true);
    }

    return (
        <div className={`
                fixed w-14 h-14 bottom-24 right-5
                rounded-full cursor-pointer
                transition ease-in-out
                hover:scale-110 active:scale-90
            `} onClick={handleClick}>
            <div className="h-full w-full flex justify-center ">
                <img className="rounded-full" src={ventionLogo}/>
            </div>
        </div>
    );
}

export default AssistantButton;
